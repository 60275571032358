.flex {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 300px;
  justify-content: space-between;
  align-items: center;
}

input {
  height: 30px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background: rgb(255, 255, 255);
  color: rgb(12, 10, 10);
  font-size: 1.1rem;
}

input::placeholder {
  color: rgba(10, 8, 8, 0.616);
  font-size: 1.1rem;
}

input:focus {
  outline: none;
}

button {
  font-size: 1.4rem;
  border: 1px black;
}

button:active {
  transform: translate(5px, 5px);
}
